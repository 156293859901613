@import '_colours.scss';

body {
    font-family: muli, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    color: #808080;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img,
svg {
    height:auto !important;
}
h1, h2, h3, h4 {
    font-weight: 300;
}
h1 {
    font-size: 3em;
}
h2 {
    font-size: 1.87em;
}
h3, h4 {
    font-size: 1.125em;
}
h3 {
    color: $text-highlight;
}
h4 {
    color: $brand-yellow;
}

.clear-float {
    clear:both;
}

.highlight {
    color:$text-highlight;
    font-size:40px;
}
.background-dark,
.teaser,
.bg-overlay {
    /* heading colours overridden in main */
    .button { /* used for paging */
        color: $text-light;
        border-color: $text-light;
        /* rest the same */
    }
}
.background-light {
    background-color:$text-light;
    color:$text-dark;
}
.offwhite-bg {
    background-color: $tint;
}
.text-dark,
.half-pane.text-dark {
    color:$text-dark;
}
.text-highlight {
    color:$text-highlight;
}

/* used in paging */
.button,
a.button {
    display: inline-block;
    font-weight: 600;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 15px;
    max-width: 100%;
    color: $text-dark;
    background:transparent;
    border: 2px solid $text-dark;
    box-sizing: border-box;
    transition: color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    [href^="#"]{
        color: $text-dark;
    }
    &:hover {
        color: $brand-blue;
        border-color: $brand-blue;
    }
    &.active {
        color: $text-highlight;
        border-color: $text-highlight;
        &:hover {
            color: $text-highlight;
            border-color: $text-highlight;
        }
    }
}

.logo-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
    img {
        max-width: 200px;
        padding: 15px;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
/** normalize the font-sizes **/
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
    }
    h3, h4 {
        font-size: 1.15em;
    }
}

@media only screen and (max-width: 300px){
    .logo-wrapper {
        img {
            max-width: 100%;
        }
    }
}