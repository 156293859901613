$brand-yellow:#F9AC1B;
$brand-pink: #E80A89;
$brand-blue: #0E7DC1;
$text-light: white;
$text-dark: #002C39;
$text-highlight: $brand-pink;
$tint: #e6e6e6;

@function tint($colour, $percentage){
    @return mix(white, $colour, $percentage);
}
@mixin darken_before($opacity: 0.3){
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0, $opacity);
}

/** shape embelishments **/
@mixin segment {
    display: block;
    width: 49px;
    height: 99px;
    background-color: $brand-yellow;
    border-bottom-left-radius: 99px;
    border-top-left-radius: 99px;
    margin: 0px 1em 1em 0px;
}
@mixin pink-segment {
    display: block;
    width: 99px;
    height: 99px;
/* have it rotated in image form instead of transform - as that is used to animate */
    background-image: url("../assets/pink-segment.svg");
    background-size: 99px 99px;
    background-repeat: no-repeat;
    background-position: center;
}
@mixin rectangle($color: $brand-blue, $w: 136px, $h: 31px){
    display: block;
    width: $w;
    height: $h;
    background-color: $color;
}
@mixin rec2($color: $brand-yellow){
    @include rectangle($color, $w: 82px, $h: 37px);
}
@mixin triangle { /* bottom left pointing arrow */
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 72px 0 0 72px;
    border-color: transparent transparent transparent $brand-pink;
}
@mixin circle($size: 130px){
    display: block;
    width: $size;
    height: $size;
    background-color: $brand-blue;
    border-radius: 50%;
}
@mixin grid {
    display: block;
    width: 148px;
    height: 140px;
    background-color: transparent;
    background-size: 148px 140px;
}
@mixin dot_matrix{
    @include grid;
    background-image: url("../assets/dot_matrix.svg");
}
@mixin slash_matrix{
    @include grid;
    background-image: url("../assets/slash_matrix.svg");
}